/*@import url('https://fonts.googleapis.com/css2?family=Chathura:wght@100;300;400;700;800&family=Itim&family=Oxanium:wght@200..800&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root, body, html {
    height: 100%;
    font-family: "Open Sans", sans-serif;

    font-optical-sizing: auto;
    font-style: normal;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wheel {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 5px solid #000;
    position: relative;
    overflow: hidden;
    transition: transform 3s ease-out;
}

.slice {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.slice span {
    transform: rotate(60deg);
    font-size: 14px;
    color: white; /* Możesz dostosować kolor tekstu, jeśli będzie mało widoczny */
}

button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

h2 {
    margin-bottom: 20px;
}

